import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../context/Loader/LoaderContext';
import { useUserContext } from '../../context/User/UserContext';
import useToast from '../../hook/useToast';
import './ProfileSetting.css';

const ProfileSetting = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state locally
  const { dispatch } = useUserContext();
  const { setLoader } = useLoader();
  const { errorToast, successToast } = useToast();
  const navigate = useNavigate();

  const handleLogOut = () => {
    try {
      setLoader(true);
      setIsLoggedIn(false); // Simulate logout by resetting local state
      dispatch({ type: 'LOG_OUT' });
      localStorage.removeItem('user')
      navigate('/');
      setLoader(false);
    } catch (error) {
      setLoader(false);
      errorToast('Failed to log out', 'Please try again');
    }
  };

  const handleDeleteAccount = () => {
    try {
      setLoader(true);
      setIsLoggedIn(false); // Simulate account deletion by resetting local state
      localStorage.removeItem('user')
      dispatch({ type: 'LOG_OUT' });
      navigate('/');
      setLoader(false);
      successToast('Account successfully deleted', '');
    } catch (error) {
      setLoader(false);
      errorToast('Failed to delete account', 'Please try again');
    }
  };

  return (
    <div className='profile-setting'>
      <div className='profile-setting__head'>
        <h2 className='profile-setting__title'>Account Settings</h2>
        <button className='logout' onClick={handleLogOut}>Log out</button>
      </div>
      <hr className='profile-setting__line' />
      <div className='profile-setting__content'>
        <h4 className='profile-setting__text'>Delete Your Account</h4>
        <p className='profile-setting__description'>
          This action will clear all your orders and user logs on the server.
        </p>
        <button onClick={handleDeleteAccount} className='profile-setting__delete-button'>
          Delete Account
        </button>
      </div>
    </div>
  );
};

export default ProfileSetting;
