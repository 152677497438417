import React, { useState } from 'react';
import '../assets/styles/services.scss'
import FirstPartyData from '../pages/FirstPartyData';
import AudienceSegments from '../pages/AudienceSegments';
import ContentPersonalization from '../pages/ContentPersonalization';
import Monetization from '../pages/Monetization';
import AudienceExpansion from '../pages/AudienceExpansion';


const Services = ({activeTab, setActiveTab}) => {
  return (
    <section className="section services" id='section--2'>
        <div className='container'>
        <div className="services__tab-container" >
            <button
            className={`btn services__tab services__tab--${activeTab === 1 ? 'active' : ''}`}
            data-tab={1}
            onClick={() => setActiveTab(1)}
            >
            Data Insights
            </button>
            <button
            className={`btn services__tab services__tab--${activeTab === 2 ? 'active' : ''}`}
            data-tab={2}
            onClick={() => setActiveTab(2)}
            >
         Audience Insights
            </button>
            <button
            className={`btn services__tab services__tab--${activeTab === 3 ? 'active' : ''}`}
            data-tab={3}
            onClick={() => setActiveTab(3)}
            >
            Conversational Commerce
            </button>
            
            <button
            className={`btn services__tab services__tab--${activeTab === 5 ? 'active' : ''}`}
            data-tab={5}
            onClick={() => setActiveTab(5)}
            >
            Customer Engagement
            </button>
        </div>
        <FirstPartyData activeTab={activeTab} />
        <AudienceSegments activeTab={activeTab} />
        <ContentPersonalization activeTab={activeTab} />
        <Monetization activeTab={activeTab} />
        <AudienceExpansion activeTab={activeTab} />
        </div>
    </section>
  );
};

export default Services;