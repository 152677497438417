import React from 'react';
import '../assets/styles/howItWorks.scss'
import smartTagImage from '../assets/images/conversation.png'
import dataAggregatorImage from '../assets/images/recs.png'
import audienceImage from '../assets/images/genai.png'

function HowItWorks() { 
  return (
    <section className="section " id="section--1">
        <div className='container howItWorks'>
            <div className="section__title">
            <h2 className="features__header">An AI E-Commerce Assistant for Product Discovery and Purchase.</h2>
            <p className="">
            AI shopping assistants to help customers discover, choose, and purchase your products effortlessly.
            </p>
            </div>

            <div className="features">
            <img
                src={smartTagImage}
                data-src="img/smarttag.jpeg"
                alt="thrivio"
                className="features__img order2"
            />
            <div className="features__feature order1">
               {/*  <div className="features__icon">
                </div> */}
                <h5 className="features__header">Enhanced Product Discovery</h5>
                <p>
                An AI assistant that enhances product discovery by recommending products and guiding customers through their journey with tailored suggestions and insights.
                </p>
            </div>

            <div className="features__feature order3">
               {/*  <div className="features__icon">
                </div> */}
                <h5 className="features__header">Get Personalized Recommendations with Nova—Just Ask!</h5>
                <p>
                Ask Nova for personalized product recommendations, insights, and content—everything you need at your fingertips with just one prompt
                </p>
            </div>
            <img
                src={dataAggregatorImage}
                data-src="img/digital.jpg"
                alt="thrivio"
                className="features__img order4"
            />

                <img
                src={audienceImage}
                data-src="img/digital.jpg"
                alt="thrivio"
                className="features__img order6"
            />
            <div className="features__feature order5">
                {/* <div className="features__icon">
                </div> */}
                <h5 className="features__header">Generative AI for Seamless Conversational Commerce</h5>
                <p>
                Easily integrate our Generative AI solution into your eommerce platform, transforming customer interactions into seamless, personalized shopping experiences.
                </p>
            </div>
            </div>
        </div>
  </section>
  )
}

export default HowItWorks