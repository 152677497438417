import React from 'react'
import audienceImage from '../assets/images/m.jpg'


function AudienceExpansion({activeTab}) {
  return (
    <div
    className={`container services__content services__content--${activeTab === 5 ? 'active' : ''}`}
    id='audience-expansion'
>
    <div className="services__header">
        <h5 >
        Enhance Customer Engagement

        </h5>
        <p>
        Leverage AI insights to deliver personalized interactions that elevate the shopping experience and increase sales.
        </p>
    </div>
    <div className="services__description">
        <div className='list_section'>
            <ul>
                <li><b>Engage Customers in Real-Time:</b> Allow customers to ask questions and receive tailored product recommendations instantly, enhancing their shopping experience.</li>
                <li><b>Foster Loyalty:</b> Build lasting relationships by delivering relevant, personalized interactions that resonate with your audience's preferences.</li>
                
            </ul>
        </div>
        <img alt='First-Party Data & Third Party Data' src={audienceImage}/>
    </div>
</div>
  )
}

export default AudienceExpansion