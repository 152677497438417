
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import userReducer from './userReducer';

// Define initial state
const initialState = {
  user: null,
};

// Create context
const UserContext = createContext(null);

// Context provider component
const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  // Check localStorage on initial load
  useEffect(() => {
    dispatch({ type: 'CHECK_LOCAL_STORAGE' });
  }, []);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use user context
const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};

export { UserContextProvider, useUserContext, UserContext };
