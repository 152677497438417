import { useNavigate } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='not-found'>
      <h2 className='not-found__title'>404</h2>
      <h3 className='not-found__discription'>Page Not Found</h3>
      <button onClick={() => navigate('/')}>Back To Home</button>
    </div>
  );
};

export default NotFound;
