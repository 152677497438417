import { useUserContext } from '../../context/User/UserContext';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import './DemoProfile.css';

const DemoProfile = () => {
  const loc = useLocation(); // Get current location
  const { state: { user } } = useUserContext(); // Get user from context

  // Check if the current path is "/demo"
  const isDemoPath = loc.pathname === '/demo';
  useEffect(() => {
    // Get the iframe element by ID
    const iframe = document.getElementById('luma_iframe'); // Change 'your_iframe_id' to the actual iframe ID

    if (iframe) {
      iframe.onload = () => {
        // Start polling for the target element inside the iframe
        const intervalId = setInterval(() => {
          const thrivioRoot = iframe.contentDocument?.getElementById('thrivio_root');
          if (thrivioRoot) {
            thrivioRoot.scrollIntoView({ behavior: 'smooth' });
            clearInterval(intervalId); // Stop polling once the element is found
          }
        }, 100); // Check every 100ms

        // Optional: stop checking after a certain amount of time to avoid infinite loops
        setTimeout(() => clearInterval(intervalId), 5000); // Stop polling after 5 seconds
      };
    }
  }, []);
  return (
    <div className='demo-profile-container container'>
      <div className='demo-profile__content'>
        {isDemoPath ? ( // Use the simplified check
          <div className='demo-content'>
            <h2 className='demo__content__welcome-message'>Welcome to the Conversational Commerce Demo!</h2>
            <p className='demo__content__instructions'>
              Have Questions? Just click on one of the question to start a conversation or Ask Nova
            </p>
            <iframe id="luma_iframe"
              title="Conversational Commerce Demo"
              src="https://demo.thrivio.co/autumn-pullie.html" 
              className="demo__iframe" 
              frameBorder="0" 
              allowFullScreen
              style={{ width: '100%', height: '600px' }} // Ensure iframe has a visible size
            />
          </div>
        ) : (
          <div>
            <h2 className='demo-profile__content__welcome-message'>Welcome {user?.name || user?.email}</h2>
            <p className='demo-profile__content__welcome-sub-message'>
              Navigate between profile pages by link in the left sidebar
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DemoProfile;
