import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client'; // Updated import
import { useUserContext, UserContextProvider } from './context/User/UserContext';
import { ToastContextProvider } from './context/Toast/ToastContext';
import { LoaderContextProvider } from './context/Loader/LoaderContext';
import App from './App';
// Initialize createRoot() once
const root = createRoot(document.getElementById('root'));

const UserAuth = () => {
    const { dispatch } = useUserContext();
    useEffect(() => {
        if (localStorage.getItem('user')) {
            let users = JSON.parse(localStorage.getItem('user'))
            dispatch({ type: 'LOG_IN', payload: users });
        }
    }, [dispatch]);
    return null;
};

// Use root.render() to render your application
root.render(
    <UserContextProvider>
        <ToastContextProvider>
            <LoaderContextProvider>
                <UserAuth />
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </LoaderContextProvider>
        </ToastContextProvider>
    </UserContextProvider>
);

export default UserAuth;

