import React from 'react'
import segmentsImage from '../assets/images/marketingactions.jpg'

function AudienceSegments({activeTab }) {
  return (
    <div
    className={`container services__content services__content--${activeTab === 2 ? 'active' : ''}`}
    id="audience-segments"
>
    <div className="services__header">
        <h5 >
        Audience Insights
        </h5>
        <p>
        Gain valuable insights into customer behaviors and preferences to deliver personalized product recommendations that enhance engagement and drive conversions.
        </p>
    </div>
    <div className="services__description">
        <img alt='First-Party Data & Third Party Data' src={segmentsImage}/>
        <div className='list_section'>
            <ul>
                <li><b>Comprehensive Customer Profiles:</b> Build complete profiles of your customers based on their interactions and preferences.</li>
                <li><b>Contextual AI Matching:</b> Use AI to recommend the right products to the right customers, optimizing their shopping experience.</li>
                <li><b>Segment Analysis:</b> Utilize segment analysis to enable personalized interactions and enhancing the conversational experience.
                </li>                
            </ul>
        </div>
    </div>
</div>
  )
}

export default AudienceSegments