import React, { useState } from 'react';
import '../assets/styles/login.scss';
import GoogleLoginButton from '../components/GoogleLoginButton';
import { Link, useNavigate, useLocation  } from 'react-router-dom';
import axios from 'axios';

function Login() {
  const [formData, setFormData] = useState({ email: "", password: "", isRegistering: false });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const messageFromQuery = query.get('message'); // Get the message from query params

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({}); // Clear errors on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    if (!formData.email.trim()) {
    }
    if (!formData.password.trim()) {
      validationErrors.password = "Password is required";
    }
    
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      //const endpoint = formData.isRegistering ? 'http://localhost:8080/api/auth/register' : 'http://localhost:8080/api/auth/login';
      const endpoint = formData.isRegistering ? 'https://auth.thrivio.co/api/auth/register' : 'https://auth.thrivio.co/api/auth/login';
      const response = await axios.post(endpoint, {
        email: formData.email,
        password: formData.password,
      });
      
      setMessage(formData.isRegistering ? "Registration successful! Please log in." : "Login successful!");
      // Handle redirection based on registration or login
      if (formData.isRegistering) {
        setFormData({ ...formData, isRegistering: false });
        // Redirect to login page immediately after registration with a message
        navigate('/login?message=Registration successful! Please log in.'); 
      } else {
          // Extract user data from response for redirecting to profile
          const { email, name, authId } = response.data.user; // Adjust based on your API response
          navigate(`/profile?email=${encodeURIComponent(email)}&name=${encodeURIComponent(name)}&authId=${encodeURIComponent(authId)}`);
      }
      // You may want to store the JWT token or user data from response
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
      setMessage(error.response ? error.response.data : "We seem to have a problem right now, please try again in some time");
    } finally {
      setLoading(false);
    }
  };

  const toggleForm = () => {
    setFormData({ ...formData, isRegistering: !formData.isRegistering });
    setErrors({});
    setMessage("");
  };

  return (
    <div className='section sign container'>
        <div>
            <h1>{formData.isRegistering ? "Create Account" : "Sign In"}</h1>
            <p>
                {formData.isRegistering 
                    ? "Already have an account? " 
                    : "New to Thrivio? "} 
                <Link to="#" onClick={toggleForm}>
                    {formData.isRegistering ? "Sign In" : "Create An Account"}
                </Link>
            </p>
        </div>
        <div className='formBlock'>
            {messageFromQuery && <p className='message'>{messageFromQuery}</p>} {/* Show the message */}
            <form onSubmit={handleSubmit}>
                <div className='formControl'>
                    <label htmlFor="email">Email </label>
                    <input 
                        type='text' 
                        placeholder='hello@thrivio.co' 
                        name='email'  
                        id='email' 
                        value={formData.email} 
                        onChange={handleChange} 
                    />
                    {errors.email && <span className='error'>{errors.email}</span>}
                </div>
                <div className='formControl'> 
                    <label htmlFor='password'>Password </label>
                    <input 
                        type='password' 
                        name='password' 
                        id='password' 
                        value={formData.password}  
                        onChange={handleChange} 
                        placeholder='******'
                    />
                    {errors.password && <span className='error'>{errors.password}</span>}
                </div>
                <button type='submit' className='btn' disabled={loading}>
                    {loading ? (formData.isRegistering ? "Registering..." : "Logging in...") : (formData.isRegistering ? "Register" : "Sign In")}
                </button>
                {message && <p className='message'>{message}</p>}
            </form>
           {/*  <p className='sep'>or</p>
            <div className='google-auth-login'>
                <GoogleLoginButton loginText="Login with Google" />
            </div> */}
        </div>
    </div>
);
}

export default Login;
