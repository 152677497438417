
import React, { useState } from 'react'; 
import '../assets/styles/banner.scss'
import bannerImage from '../assets/images/poster.png'
import bannerVideo from '../assets/videos/Thrivio.mp4'
import { useNavigate } from 'react-router-dom';
function Banner() {
  const navigate = useNavigate();
  const [email, setEmail] = useState(''); // State to manage email input
  const handleContactUsButtonClick = () => {
    // Custom logic on button click (e.g., tracking, smooth scroll, etc.)
    console.log('Button clicked!');

    // Now navigate to the Contact Us page
    navigate('/contactus', { state: { email } });
  };

  return (
    <div className='section banner container' id='banner-section'>
        <div className="banner_title">
            <h1>
            Your AI E-Commerce Assistant
            <span className="highlight"> </span> 
              for
            <br />
            <span className="highlight">Conversational Commerce </span> 

             
            </h1>
            <p>Empower Your Shopping Journey: Ask, Discover, and Buy Effortlessly with Nova

            </p>
            <div className='banner_action'>
                <input className='form_input' type='text' placeholder='Type your email' value={email} // Bind input to state
                onChange={(e) => setEmail(e.target.value)} />
                <button className="btn btn--scroll-to" onClick={handleContactUsButtonClick}>Book a Demo</button>
            </div>

            
         

        <video 
          className="banner_img" 
          width="600"   // Set your desired width
           // Set your desired height
          controls  
          poster={bannerImage}
          alt="Thrivio-Video">
          <source src={bannerVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>


        </div>
      </div>
  )
}

export default Banner