import { Link, useLocation } from "react-router-dom";
import '../assets/styles/header.scss';
import logo from '../assets/images/thrivio-high-resolution-logo-transparent.png';
import LoginButton from './LoginButton/LoginButton';
import { BiChevronDown, BiChevronUp, BiMenu, BiX } from "react-icons/bi";
import { useEffect, useState } from "react";

const NavBar = ({ activeTab, setActiveTab }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [navbarActive, setNavbarActive] = useState(false);
    const location = useLocation();
  
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if(location.pathname === '/login' || location.pathname === '/createLogin') {
            window.scrollTo(0, 0);
        }
        // Ensure this effect does not cause a re-render loop
        setNavbarActive(false);
    }, [location.pathname]); // Add location.pathname as dependency

    const toggleNavbar = () => {
        setNavbarActive(prevState => !prevState); // Use functional update to avoid stale state issues
    };

    const handleTabClick = (tab, number) => {
        setActiveTab(number);
        const sectionId = `${tab}`;
        const section = document.getElementById(sectionId);
        if (section) {
            setTimeout(() => {
                section.scrollIntoView({ behavior: 'smooth' });
            }, 100); 
        }
    };

    return (
        <header className="header sticky">
            <div className='container'>
                <nav className={`nav`}>
                    <Link to={'/'}> 
                        <img src={logo} alt="Thrivio Logo" className="nav__logo" id="logo" />
                    </Link>
                    
                    <div className="nav__buttons">
                        {
                            location.pathname !== '/login' && location.pathname !== "/createLogin" && (
                                <ul className={`nav__links ${windowWidth < 1100 && navbarActive ? 'active' : ''}`}>
                                    {/*<li className="nav__item">
                                        <Link className="nav__link" to={'/'} onClick={() => handleTabClick('home', 1)}>Home</Link>
                                    </li>
                                    <li className="nav__item">
                                        <Link className="nav__link products" to={'#'}>Products <BiChevronDown size={24} /></Link>
                                        <ul className={`dropdown`} id="dropdown">
                                            <li className="dropdown__item">
                                                <Link className="dropdown__link" onClick={() => handleTabClick('section--2', 1)}>Data Insights</Link>
                                            </li>
                                            <li className="dropdown__item">
                                                <Link className="dropdown__link" onClick={() => handleTabClick('section--2', 2)}>Audience Insights</Link>
                                            </li>
                                            <li className="dropdown__item">
                                                <Link className="dropdown__link" onClick={() => handleTabClick('section--2', 3)}>Conversational Commerce</Link>
                                            </li>                        
                                            <li className="dropdown__item">
                                                <Link className="dropdown__link" onClick={() => handleTabClick('section--2', 5)}>Customer Engagement</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav__item">
                                        <Link className="nav__link" to={'/'}>About</Link>
                                    </li>*/}
                                </ul>
                            )
                        }
                         {/* <LoginButton />*}
                        {/* {
                            location.pathname !== '/login' && location.pathname !== "/createLogin" && (
                                <button className="toggle" onClick={toggleNavbar}>
                                    {navbarActive ? <BiX size={24} color="white" /> : <BiMenu size={24} color="white" />}
                                </button>
                            )
                        } */}
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default NavBar;
