import { createContext, FC, ReactNode, useContext, useState } from 'react';

const LoaderContext = createContext({});

const LoaderContextProvider = ({ children }) => {
  const [isLoading, setLoader] = useState(false);

  return <LoaderContext.Provider value={{ isLoading, setLoader }}>{children}</LoaderContext.Provider>;
};

const useLoader = () => useContext(LoaderContext);
export { LoaderContextProvider, useLoader };
