   
import React from 'react';
import '../assets/styles/sidebar.scss'
import 'boxicons/css/boxicons.min.css'; // Import Boxicons CSS from CDN

const Products = () => {
  return (
    <>
      <section className="home-section products">
        <div className="text">Products</div>
      </section>
    </>
  );
};

export default Products;
