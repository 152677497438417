   
import React from 'react';
import '../assets/styles/sidebar.scss'
import 'boxicons/css/boxicons.min.css'; // Import Boxicons CSS from CDN

const AudienceInsights = () => {
  return (
    <>
      <section className="home-section audience_segements">
        <button className="audience_segements_btn">Generate Audience Insights</button>
        <div className="text">Audience Insights</div>
      </section>
    </>
  );
};

export default AudienceInsights;
