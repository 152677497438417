   
import React from 'react';
import '../assets/styles/sidebar.scss'
import 'boxicons/css/boxicons.min.css'; // Import Boxicons CSS from CDN

const SmartTag = () => {
  return (
    <>
      <section className="home-section smartTag">
        <div className="text">JavaScript Tag Generator</div>
        <button className='tag_generator_btn'>Generator Tag</button>
      </section>
    </>
  );
};

export default SmartTag;
