import { useLoader } from '../../context/Loader/LoaderContext';
import { useUserContext } from '../../context/User/UserContext';
import useForm from '../../hook/useForm';
import useToast from '../../hook/useToast';
import './ProfileDetails.css';

const ProfileDetails = () => {
  // Remove Firebase authentication related imports and constants

  const { setLoader } = useLoader();
  const { errorToast, succsesToast } = useToast();
  const {state: { user }, dispath,} = useUserContext();

  const handleUpdateProfile = async (values) => {
    try {
      setLoader(true);
      setLoader(false);
      dispath({ type: 'LOG_IN', payload: { /* Update with the user data from your backend */ } });
      succsesToast('Profile successfully updated', '');
    } catch (error) {
      errorToast('Cannot update the profile', 'Please try again later');
      setLoader(false);
    }
  };

  const { handleChange, handleSubmit, values } = useForm(handleUpdateProfile, {
    name: user.name || '',
    email: user.email || '',
  });
  
  let userName = values?.name;
  if(userName == "null") {
    userName = values?.email.split("@")[0]
  }
  return (
    <div className='profile-details'>
      <h2 className='profile-details__title'>My Details</h2>
      <div className='profile-details__personal-info'>
        <h4 className='profile-details__personal-info__title'>Personal Information</h4>
        <hr className='profile-details__line' />
        <form onSubmit={handleSubmit} className='profile-details__form'>
          <div className='profile-details__form__inputs'>
            <div className='profile-details__name'>
              <span>Name : </span>
              <input
                required
                onChange={handleChange}
                name='name'
                placeholder='Name'
                type='text'
                value={userName}
                readOnly
              />
            </div>
            <div className='profile-details__email'>
              <span>Email : </span>
              <input
                required
                name='email'
                onChange={handleChange}
                value={values.email}
                placeholder='Email'
                type='email'
                readOnly
              />
            </div>
          </div>
          {/* <button className='profile-details__submit-button' type='submit'>
            Update
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default ProfileDetails;
