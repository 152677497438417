import { useEffect, useState } from 'react';
import { useUserContext } from '../context/User/UserContext';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const { state, dispatch } = useUserContext(); // Combine context hooks
    const navigate = useNavigate();

    useEffect(() => {
        const authenticate = () => {
            let users = "";
            if (localStorage.getItem('user')) {
                users = JSON.parse(localStorage.getItem('user'));
            }

            const query = window.location.search;
            const queryParams = new URLSearchParams(query);
            const parsedParams = {
                email: queryParams.get('email'),
                name: queryParams.get('name'),
                authId: queryParams.get('authId'),
            };

            if (queryParams.get('email') !== null && queryParams.get('email') !== "") {
                console.log("queryParams.get('email') = " , queryParams.get('email'))
                users = parsedParams;
                dispatch({ type: 'LOG_IN', payload: users });
                console.log("state.user", state.user)
            }

            if (state.user || users) {
                setIsAuth(true);
                setLoading(false);
            } else {
                setTimeout(() => {
                    setIsAuth(false); // Simulate unauthenticated state
                    setLoading(false);
                }, 1000); // Simulate delay
            }
            setTimeout(() => {
                if (queryParams.get('email') !== null && queryParams.get('email') !== "") {
                    //navigate('/firsttimeuser');
                    navigate('/demo');
                }
            }, 1000); 
        };

        authenticate();
    }, [state.user, dispatch, navigate]); // Adjust dependencies

    return { loading, isAuth };
};

export default useAuth;
