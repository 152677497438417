
const userReducer = (state, action) => {
  switch (action.type) {
    case 'LOG_IN':
      // Save user data to localStorage
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };
    case 'LOG_OUT':
      // Remove user data from localStorage
      localStorage.removeItem('user');
      return {
        ...state,
        user: null,
      };
    case 'CHECK_LOCAL_STORAGE':
      // Check if user data exists in localStorage on app load
      const storedUser = JSON.parse(localStorage.getItem('user'));
      return {
        ...state,
        user: storedUser,
      };
    default:
      return state;
  }
};

export default userReducer;
