
import {BrowserRouter, Router, Routes, Route, Switch  } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/Footer';
import Login from './pages/Login';
import NavBar from './components/NavBar';
import FirstPartyData from './pages/FirstPartyData';
import AudienceSegments from './pages/AudienceSegments';
import ContentPersonalization from './pages/ContentPersonalization';
import Monetization from './pages/Monetization';
import NotFound from './pages/NotFound/NotFound';
import Profile from './pages/Profile/Profile';
import ProfileDetails from './Layout/ProfileDetails/ProfileDetails';
import ProfileSetting from './Layout/ProfileSetting/ProfileSetting';
import PrivetRoute from './routes/PrivetRoute';
import CreateLogin from './pages/CreateLogin';
import './assets/styles/common.scss';
import AudienceExpansion from './pages/AudienceExpansion';
import { useState, useEffect } from 'react';
import FirstTimeUser from './pages/FirstTimeUser';
import Sidebar from './dashboard/Sidebar';
import Dashboard from './dashboard/Dashboard';
import User from './dashboard/User';
import SmartTag from './dashboard/SmartTag';
import AudienceInsights from './dashboard/AudienceInsights';
import MarketingAutomation from './dashboard/MarketingAutomation';
import Products from './dashboard/Products';
import Setting from './dashboard/Setting';
import DemoProfile from './pages/Profile/DemoProfile';
import ContactUs from './pages/ContactUs';
function App() {
  const [activeTab, setActiveTab] = useState(1);
  const [showDashboard, setShowDashboard] = useState(false);
  // useEffect(() => {
  //   Check if localStorage value is 'dashboard'
  //   const localStorageValue = localStorage.getItem('dashboard');
  //   setShowDashboard(localStorageValue === 'true');
  // }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="App" id='home'>
      <BrowserRouter>
      {showDashboard ? (
          <>
            <Sidebar />
            <div className='thrivio-wrapper'>
              <Routes>
                <Route path="/dashboard" element={<Dashboard/>} > </Route>
                <Route path="/user" element={<User/>} > </Route>
                <Route path="/smarttag" element={<SmartTag/>} > </Route>
                <Route path="/audienceinsights" element={<AudienceInsights/>} > </Route>
                <Route path="/marketingautomation" element={<MarketingAutomation/>} > </Route>
                <Route path="/products" element={<Products/>} > </Route>
                <Route path="/setting" element={<Setting/>} > </Route>
              </Routes>
            </div>
            
          </>
        ) : (
          <>
          <NavBar activeTab={activeTab} setActiveTab={handleTabClick}/>
          <div className='thrivio-wrapper'>
            <Routes>
              <Route path="/" element={<Home activeTab={activeTab} setActiveTab={handleTabClick}/>} > </Route>
              <Route path="/home" element={<Home activeTab={activeTab} setActiveTab={handleTabClick}/>} > </Route>
              <Route path="/login" element={<Login/>} > </Route>
              <Route path='/createLogin' element={<CreateLogin />}></Route>
              <Route path='/contactus' element={<ContactUs />} ></Route>
              <Route path='*' element={<NotFound />} />
              {/* privet pages */}
                <Route element={<PrivetRoute />}>
                  <Route path='/profile' element={<Profile />} >
                    <Route path='details' element={<ProfileDetails />} />
                    <Route path='setting' element={<ProfileSetting />} />
                  </Route>
                  <Route path='/demo' element={<DemoProfile />} >
                  </Route>
                  {/*<Route path='/firsttimeuser' element={<FirstTimeUser />} /> */}
                </Route>
              {/* privet pages */}
            </Routes>
          </div>
          <Footer />
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
