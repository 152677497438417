import React from 'react'
import marketingImage from '../assets/images/ms.jpeg'

function ContentPersonalization({activeTab }) {
  return (
    <div
    className={`container services__content services__content--${activeTab === 3 ? 'active' : ''}`}
    id='content-personalization'
>
    <div className="services__header">
        <h5 >
        Conversational Commerce Optimization
        </h5>
        <p>
        Leverage AI to enhance customer interactions and product recommendations through conversational commerce.
        </p>
    </div>
    <div className="services__description">
        <div className='list_section'>
            <ul>
                <li><b>Personalize Interactions: </b>Use AI-driven insights to engage customers with tailored responses and product suggestions in real time.</li>
                <li><b>Increase Engagement & Sales: </b>Foster meaningful conversations that resonate with your audience, leading to higher engagement and conversion rates.
                </li>
                <li><b>Empower Customer Decisions: </b>Provide instant, relevant product recommendations to guide customers through their shopping journey.</li>
               
            </ul>
        </div>
        <img alt='First-Party Data & Third Party Data' src={marketingImage}/>
    </div>
</div>
  )
}

export default ContentPersonalization