import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import LoaderPage from '../components/LoaderPage/LoaderPage';
import useAuth from '../hook/useAuth';

const PrivetRoute = () => {
  const { loading, isAuth } = useAuth();
  console.log("isAuth", isAuth)
  if (loading) {
    return <LoaderPage />;
  }
  return isAuth ? <Outlet /> : <Navigate to='/login' />;
};

export default PrivetRoute;
