import servicesImage from '../assets/images/m.jpg'
import React from 'react'

function Monetization({activeTab }) {
  return (
    <div
            className={`container services__content services__content--${activeTab === 4 ? 'active' : ''}`}
            id='monetization'
        >
            <div className="services__header">
                <h5 >
                Optimized Ad Placement
                </h5>
                <p>
                Strategically position ads on your site, choose best performing ad formats to enhance user experience and increase monetization opportunities.
                </p>
            </div>
            <div className="services__description">
            <img alt='First-Party Data & Third Party Data' src={servicesImage}/>
                <div className='list_section'>
                    <h3>Optimized Ad Placement</h3>
                    <ul>
                        <li>Dynamic Ad Postition</li>
                        <li>Optimum Ad Quantity</li>
                        <li>Ad Format Optimization</li>
                        <li>Ad Refresh Rate</li>
                        <li>Brand Safety Assurance</li>
                    </ul>
                </div>
            </div>
        </div>
  )
}

export default Monetization