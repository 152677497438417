import React from 'react'
import '../assets/styles/login.scss'
import GoogleLoginButton from '../components/GoogleLoginButton';
import { Link } from 'react-router-dom';


function CreateLogin() {
  return (
    <div className='section create-sign-in sign'>
        <div>
          <h1>Create Your Account</h1>
          <p>Already Registered? <Link to={'/login'} >Login</Link></p>

          
        </div>
        <div className='formBlock'>
          <form>
          <div className='formControl'>
              <label htmlFor="email">Name </label> 
              <input type='text' placeholder='Username' name='name'  id='name'  />
            </div>
            <div className='formControl'>
              <label>Email  </label>
              <input type='text' placeholder='hello@thrivio.co' name='email' />
            </div>
            <div className='formControl'>
              <label>Password  </label>
              <input type='password' name='password' placeholder='*******' />
            </div>
            <div className='formControl'>
              <label>Confirm Password  </label>
              <input type='password' name='confirmPassword' placeholder='*******' />
            </div>
            <button type='submit' className='btn'>Sign Up</button>
          </form>
          <p className='sep'>Or</p>
          <div className='google-auth-login'><GoogleLoginButton Logintext="Continue with Google" /></div>
        </div>
    </div>
  )
}

export default CreateLogin