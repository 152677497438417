   
import React from 'react';
import '../assets/styles/sidebar.scss'
import 'boxicons/css/boxicons.min.css'; // Import Boxicons CSS from CDN

const Setting = () => {
  return (
    <>
      <section className="home-section setting">
        <div className="text">Setting</div>
      </section>
    </>
  );
};

export default Setting;
