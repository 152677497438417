import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../assets/styles/contactus.scss';

const ContactUs = () => {
  const location = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  // Update form data state when inputs change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true); // Show success message immediately

    try {
      // Send POST request in the background
      await fetch('https://formsubmit.co/0e94f0e3004c846912922b178e9aefdb', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData)
      });
    } catch (error) {
      console.error('Form submission failed:', error);
    }
  };

  return (
    <div className='section contact container'>
      <div>
        <h1>We’d Love to Hear From You!</h1>
        <p>Whether you're curious about our demo, have questions, or just want to say hello, feel free to reach out.</p>
      </div>
      <div className='formBlock'>
        {!submitted ? (
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_template" value="table" />

            <div className='formControl'>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                required
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            <div className='formControl'>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                placeholder="Enter your email"
                value={formData.email || email} // Pre-fill if email exists
                onChange={handleInputChange}
              />
            </div>

            <div className='formControl'>
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                required
                placeholder="Write your message here"
                rows="5"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <button type="submit" className="btn">Send Message</button>
          </form>
        ) : (
          <div className="success-message">
            <h2>Thank you for contacting us!</h2>
            <p>We will get back to you soon.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
