import React from 'react'
import Banner from '../components/Banner'
import HowItWorks from '../components/HowItWorks'
import Services from '../components/Services'

function Home({activeTab, setActiveTab}) {
  return (
    <>
    <Banner />
    <HowItWorks />
    <Services activeTab={activeTab} setActiveTab={setActiveTab}/>
  </>
  )
}

export default Home