import { FormEvent, useState } from 'react';


const useForm = (submitFunction, defaultValue) => {
  const [values, setValues] = useState(defaultValue);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitFunction();
  };

  return { values, handleChange, handleSubmit };
};

export default useForm;
