import React, { useState, useEffect } from 'react';
import '../assets/styles/dashboard.scss'; 
import '../assets/styles/sidebar.scss'; // Import your sidebar styles
import 'boxicons/css/boxicons.min.css'; // Import Boxicons CSS from CDN

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [pageSpeedData, setPageSpeedData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPageSpeedData = async () => {
      try {
        const apiKey = 'AIzaSyC6hghb1-zu9QIVXuN-p8K1lyNSf_iuJq4';
        const url = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=https://www.myntra.com/&key=${apiKey}&strategy=desktop`;
  
        const response = await fetch(url);
        const data = await response.json();
  
        setPageSpeedData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching PageSpeed Insights data:', error);
        setError('Failed to fetch PageSpeed Insights data.');
        setLoading(false);
      }
    };
  
    if (!pageSpeedData) {
      fetchPageSpeedData();
    }
  }, []); // Empty dependency array to run only once on mount

  if (loading) {
    return <div className="dashboard-container">Loading...</div>;
  }

  if (error) {
    return <div className="dashboard-container">Error: {error}</div>;
  }

  if (!pageSpeedData) {
    return <div className="dashboard-container">Failed to fetch PageSpeed Insights data.</div>;
  }

  const { lighthouseResult, loadingExperience, originLoadingExperience } = pageSpeedData;

  return (
    <div className="dashboard-container">
      <section className="home-section dashboard">
        <div className="text">SEO Performance Metrics</div>
        
        <div className="performance-metrics">
          <h2>Performance Metrics:</h2>
          <ul>
            {loadingExperience && loadingExperience.metrics && (
              <>
                <li>
                  <strong>Cumulative Layout Shift (CLS) Score:</strong> {loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE?.percentile}
                  {loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE?.category && <p>{loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.category}</p>}
                </li>
                <li>
                  <strong>Experiment Time to First Byte (ETFB):</strong> {loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS?.percentile} ms
                  {loadingExperience.metrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE?.category && <p>{loadingExperience.metrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE.category}</p>}
                </li>
                <li>
                  <strong>First Contentful Paint (FCP):</strong> {loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS?.percentile} ms
                  {loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS?.category && <p>{loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.category}</p>}
                </li>
                <li>
                  <strong>Largest Contentful Paint (LCP):</strong> {loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS?.percentile} ms
                  {loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS?.category && <p>{loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.category}</p>}
                </li>
                <li>
                  <strong>FIRST INPUT DELAYMS (FID):</strong> {loadingExperience.metrics.FIRST_INPUT_DELAY_MS?.percentile} ms
                  {loadingExperience.metrics.FIRST_INPUT_DELAY_MS?.category && <p>{loadingExperience.metrics.FIRST_INPUT_DELAY_MS.category}</p>}
                </li>
              </>
            )}
            {originLoadingExperience && originLoadingExperience.metrics && (
              <>
                <li>
                  <strong>Origin CLS Score:</strong> {originLoadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE?.percentile}
                  {originLoadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE?.category && <p>{originLoadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.category}</p>}
                </li>
                <li>
                  <strong>Experiment Time to First Byte (ETFB):</strong> {originLoadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS?.percentile} ms
                  {originLoadingExperience.metrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE?.category && <p>{originLoadingExperience.metrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE.category}</p>}
                </li>
                <li>
                  <strong>Origin FCP:</strong> {originLoadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS?.percentile} ms
                  {originLoadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS?.category && <p>{originLoadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.category}</p>}
                </li>
                <li>
                  <strong>Origin LCP:</strong> {originLoadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS?.percentile} ms
                  {originLoadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS?.category && <p>{originLoadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.category}</p>}
                </li>
                <li>
                  <strong>FIRST INPUT DELAYMS (FID):</strong> {originLoadingExperience.metrics.FIRST_INPUT_DELAY_MS?.percentile} ms
                  {originLoadingExperience.metrics.FIRST_INPUT_DELAY_MS?.category && <p>{originLoadingExperience.metrics.FIRST_INPUT_DELAY_MS.category}</p>}
                </li>
              </>
            )}
          </ul>
        </div>
        
        {/* Display SEO Categories */}
        <div className="seo-categories">
          <h2>SEO Categories:</h2>
          <ul>
            <li>
              <strong>Mobile Friendly:</strong> {lighthouseResult.categoryGroups['seo-mobile']?.description || 'Not available'}
            </li>
            <li>
              <strong>Content Best Practices:</strong> {lighthouseResult.categoryGroups['seo-content']?.description || 'Not available'}
            </li>
            <li>
              <strong>Crawling and Indexing:</strong> {lighthouseResult.categoryGroups['seo-crawl']?.description || 'Not available'}
            </li>
            {/* Add more categories as needed */}
          </ul>
          
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
