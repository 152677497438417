import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import googleIcon from '../assets/images/google.png';

const GoogleLoginButton = ({ loginText }) => {
    const handleLogin = () => {
        window.location.href = 'https://auth.thrivio.co/oauth2/authorization/google';
    };

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '250px',
        height: '50px',
        backgroundColor: '#ffffff',
        color: '#000',
        fontSize: '16px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    const textStyle = {
        marginLeft: '10px', // Adjust the spacing as needed
        fontWeight: '500', // Example style
    };

    return (
        <div className="google-login">
            <button onClick={handleLogin} style={buttonStyle}>
                <img src={googleIcon} alt="Google logo" style={{ width: '30px', height: '30px' }} />
                <span style={textStyle}>{loginText}</span>
            </button>
        </div>
    );
};

// Define prop types for validation
GoogleLoginButton.propTypes = {
    loginText: PropTypes.string.isRequired, // loginText should be a required string
};

export default GoogleLoginButton;
