import { useUserContext } from '../../context/User/UserContext';
import { Link, Outlet, useLocation } from 'react-router-dom';
import ProfileSideBar from '../../Layout/ProfileSideBar/ProfileSideBar';
import './Profile.css';


const Profile = () => {
  const loc = useLocation();
  const pathname = loc.pathname.split('/')[2];
  const {state: { user },} = useUserContext();
  let userName = user?.name;
  if(userName == "null") {
    userName = user?.email.split("@")[0]
  }
  return (
    <div className='profile-container container'>
      <div className='profile__sidebar'>
        <ProfileSideBar />
      </div>
      <div className='profile__mobile-navigation'>
        <Link className={`profile__mobile-navigation__link ${pathname === 'details' ? 'active' : ''}`} to='details'>
          Profile Details
        </Link>
        <Link className={`profile__mobile-navigation__link ${pathname === 'setting' ? 'active' : ''}`} to='setting'>
          Account Setting
        </Link>
      </div>
      <h2 className='profile__mobile__name'>{userName}</h2>
      <div className='profile__content'>
        {loc.pathname.split('/')[2] ? (
          <Outlet />
        ) : (
          <div>
            <h2 className='profile__content__welcome-message '>Welcome {userName || user?.email} </h2>
            <p className='profile__content__welcome-sub-message'>
              Navigate between profile pages by link in left sidebar{' '}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
