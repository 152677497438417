import React from 'react'
import '../assets/styles/firsttimeuser.scss'


function FirstTimeUser({activeTab}) {
  return (
    <div className="container firstTimeUser__content">
        <div className="firstTimeUser__header">
            <h5 >
                Lets Understand Your Business - First Time User
            </h5>
        </div>
        <div className="firstTimeUser__description">
            <div className='website__url'>
                <label htmlFor="webUrl">Please enter you website URL </label> 
                <input type='text' placeholder='Website URL' name='webUrl'  id='webUrl'  />
            </div>
            <div className='website_tab_container'>
                <p>What best describes your website?</p>
                <div className='website_tab_list'>
                    <div className='website_tab retail_ecommerce'>Retail & Ecommerce</div>
                    <div className='website_tab digital_media'>Digital Media</div>
                    <div className='website_tab others'>Others</div>
                    <div className='website_tab businessType'>Please mention your business type</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FirstTimeUser