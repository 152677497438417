import React from 'react'
import '../assets/styles/footer.scss'
import { Link } from 'react-router-dom'
import { useUserContext } from '../context/User/UserContext';

function Footer() {
  const {state: { user },} = useUserContext();
  return (
    <div>
    {!user && 
      <section className="section section--sign-up">
        <div className="section__title">
          <h3 className="section__header">
          Boost your eCommerce with Thrivio’s AI assistant for seamless conversational commerce.
          </h3>
        </div>
        <Link to='/createLogin' >
          <button className="btn btn--show-modal">Open your account today!</button>
        </Link>
      </section>
    }
    <footer className="footer container">
      <p className="footer__copyright">
        &copy; Copyright by
        <span
          className="footer__link "
          > <Link to='/' >Thrivio</Link></span> - All Rights Reserved
      </p>
      <ul>
        <li><Link to='/' >Privacy Policy</Link></li>
        <li><Link to='/' >Terms and Conditions</Link></li>
      </ul>
    </footer>
    </div>
  )
}

export default Footer