
import React from 'react';
import { Link, useLocation } from "react-router-dom";
import '../assets/styles/sidebar.scss'
import 'boxicons/css/boxicons.min.css'; // Import Boxicons CSS from CDN
import { useUserContext } from '../context/User/UserContext';
// import logo from '../assets/images/newlogo.jpg'

const Sidebar = () => {
  const { state } = useUserContext();
  const closeBtn = (element) => {
    let sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("open");
    menuBtnChange(sidebar, element);//calling the function(optional)
  }

  const menuBtnChange = (sidebar, closeBtn) => {
    if(sidebar.classList.contains("open")){
      closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
    }else {
      closeBtn.classList.replace("bx-menu-alt-right","bx-menu");//replacing the iocns class
    }
  }
  return (
    <>
      <div className="sidebar" >
        <div className="logo-details">
          <i className="bx bxl-c-plus-plus icon"></i>
          {/* <img src={logo} alt="Thrivio Logo" className="nav__logo" id="logo"  /> */}
          <div className="logo_name">Thrivio</div>
          <i className="bx bx-menu" id="btn" onClick={(e)=>closeBtn(e.target)}></i>
        </div>
        <ul className="nav-list">
          {/* Navigation items */}
          <li>
            <Link to={'/dashboard'}>
              <i className="bx bx-grid-alt"></i>
              <span className="links_name">Dashboard</span>
            </Link>
            <Link to={'/dashboard'} className="tooltip">Dashboard</Link>
          </li>
          <li>
            <Link to={'/user'}>
              <i className="bx bx-user"></i>
              <span className="links_name" >User</span>
            </Link>
            <Link to={'/user'} className="tooltip" >User</Link>
          </li>
          <li>
            <Link to={'/smarttag'}>
              <i className="bx bx-code-alt"></i>
              <span className="links_name" >Smart Tag</span>
            </Link>
            <Link className="tooltip" to={'/smarttag'}>Smart Tag</Link>
          </li>
          <li>
            <Link to={'/audienceinsights'}>
              <i className="bx bx-pie-chart-alt-2"></i>
              <span className="links_name" >Audience Insights</span>
            </Link>
            <Link className="tooltip" to={'/audienceinsights'}>Audience Insights</Link>
          </li>
          <li>
            <Link to={'/marketingautomation'}>
              <i className="bx bx-book-content"></i>
              <span className="links_name">Conversational Commerce</span>
            </Link>
            <Link className="tooltip" to={'/marketingautomation'}>Conversational Commerce</Link>
          </li>
          <li>
            <Link to={'/products'}>
              <i className="bx bx-folder"></i>
              <span className="links_name" >Products</span>
            </Link>
            <Link className="tooltip" to={'/products'}>Products</Link>
          </li>
          <li>
            <Link to={'/setting'}>
              <i className="bx bx-cog"></i>
              <span className="links_name" >Setting</span>
            </Link>
            <Link className="tooltip" to={'/setting'}>Setting</Link>
          </li>
          <li className="profile">
            <div className="profile-details">
              <div className="name_job">
                <div className="name">Name</div>
                <div className="job">{state?.user?.name}</div>
              </div>
            </div>
            <i className="bx bx-log-out" id="log_out"></i>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
