import { createContext, ReactNode, Reducer, useReducer, useContext } from 'react';
import toastReducer from './ToastReducer';
const ToastContext = createContext({});

const initialState = {
  isShow: false,
  type: '',
  title: '',
  discription: '',
};
const ToastContextProvider = ({ children }) => {
  const [state, dispath] = useReducer(toastReducer, initialState);

  return <ToastContext.Provider value={{ state, dispath }}>{children}</ToastContext.Provider>;
};

const useToastContext = () => useContext(ToastContext);

export { ToastContextProvider, useToastContext };
export default ToastContext;
