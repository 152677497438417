import React from 'react'
import fpdImage from '../assets/images/fpd.jpg'

function FirstPartyData({activeTab}) {
  return (
    <div
    className={`container services__content services__content--${activeTab === 1 ? 'active' : ''}`}
    id="data-collection"
>
    <div className="services__header">
        <h5 >
        Unlock Powerful Data Insights
        </h5>
        <p>
        Harness AI-driven technology to provide personalized product suggestions, enhancing the shopping journey for your customers.
        </p>
    </div>
    <div className="services__description">
        <div className='list_section'>
            <ul>
                <li><b>Know Your Customers: </b>Use AI to provide tailored product recommendations based on customer preferences.</li>
                <li><b>Personalized Experiences: </b>Leverage data-driven insights to deliver highly personalized interactions, enhancing customer satisfaction and loyalty. </li>
                <li><b>Privacy Compliance: </b>Ensure data privacy and compliance while building customer loyalty.</li>
               
            </ul>
        </div>
        <img alt='First-Party Data & Third Party Data' src={fpdImage}/>
    </div>
    </div>
  )
}

export default FirstPartyData